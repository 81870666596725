import styles from './menu.module.css';
import { menuSocMedia } from '@/shared/mockData/menuMockData';

export interface MenuItemType {
  id: number;
  title: string;
  children?: MenuItemType[];
}

interface DesktopMenuProps {
  menuContent: MenuItemType[];
}

export const DesktopMenu = ({ menuContent }: DesktopMenuProps) => {
  const filteredMenuContent = menuContent.flatMap((item) => {
    if (item.title === 'Информация' && item.children) {
      return item.children;
    }
    return item.title !== 'Информация' ? [item] : [];
  });

  return (
    <div className={`${styles.menuContainer} ${styles.desktop}`}>
      <ul className={styles.menuContentWrapper}>
        {filteredMenuContent.map((item) => (
          <li key={item.id} className={styles.menuContentItem}>
            {item.title}
          </li>
        ))}
      </ul>
      <div className={styles.menuContentIconWrapper}>
        {menuSocMedia &&
          menuSocMedia.map((el) => (
            <div key={el.id} className={styles.menuContentIcon}>
              {el.icon}
            </div>
          ))}
      </div>
    </div>
  );
};
