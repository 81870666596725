import styles from './navWrapper.module.css';
import { Navbar } from './navbar/navbar';

export const NavWrapper = () => {
  return (
    <div className={styles.navWrapper}>
      <Navbar />
    </div>
  );
};
