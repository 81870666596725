import styles from './president-сard.module.css';
import { Quote } from '@/shared/ui/icons/Quote';
import { Close } from '@/shared/ui/icons/Close';
import { Play } from '@/shared/ui/icons/Play';
import { ProfileCardType } from '../../model/types';

interface ProfileCardProps {
  items: ProfileCardType;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const ProfileCard = ({ items,isExpanded, onToggleExpand }:ProfileCardProps) => {
  const handleToggleExpand = () => {
    onToggleExpand();
  };

  return (
    <div className={`${styles.cardContainer} ${isExpanded ? styles.cardExpanded : ''}`}>
      <div className={styles.profileImageContainer}>
        <div className={`${styles.profileImageWrapper} ${isExpanded ? styles.profileImageExpanded : ''}`}>
          <img src={items.imgSrc} alt={`${items.fullName}'s photo`} className={styles.profileImage} />
          <button
            className={`${styles.expandButton} ${isExpanded ? styles.hiddenButton : ''} ${
              isExpanded ? styles.rotateBackwards : styles.rotateForwards
            }`}
            onClick={handleToggleExpand}
          >
            <Close />
          </button>
        </div>
        <div className={styles.nameTitleContainer}>
          <div className={styles.name}>{items.fullName}</div>
          <div className={styles.title}>{items.jobTitle}</div>
        </div>
      </div>

      <div className={styles.profileInfoContainer}>
        <div className={styles.quoteIcon}>
          <Quote />
        </div>
        <div className={styles.quote}>{items.quote}</div>
        <div className={`${styles.detailsContainer} ${isExpanded ? styles.detailsVisible : ''}`}>
          <div>{items.biography}</div>
          <div>{items.details}</div>
          <div className={styles.description}>{items.description}</div>
          {items.interview && <div className={styles.underline}></div>}
          <div>
            {items.interview?.map((item, i) => (
              <div key={i} className={styles.interviewContainer}>
                <div className={styles.playIcon}>
                  <Play />
                </div>
                <div>
                  {item.title}
                  <a className={styles.interviewBtn} target="_blank" href={item.link}>
                    Смотреть
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        className={`${styles.closeButton} ${!isExpanded ? styles.hiddenButton : ''} ${
          isExpanded ? styles.rotateBackwards : styles.rotateForwards
        }`}
        onClick={handleToggleExpand}
      >
        <Close />
      </button>
    </div>
  );
};
