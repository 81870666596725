import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  videoContainer: {
    width: '100%',
    height: '46rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingBottom: 0,
    '@media (max-width: 768px)': {
      height: '64rem',
    },
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  playButton: {
    ':not(#__unused__) > svg': {
      width: '32px',
      margin: '0 auto',
      fill: 'none',
      stroke: colors.primaryIcon,
    },
    background: colors.primaryBackground,
    border: 'none',
    borderRadius: '50%',
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(1)',
    width: '76px',
    height: '76px',
    transition: 'transform 0.3s ease',
    '@media (max-width: 768px)': {
      width: '68px',
      height: '68px',
    },
  },
  playButtonHover: {
    transform: 'scale(1.1)',
    transition: 'transform 0.3s ease',
  },
  btnWrap: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btnText: {
    marginTop: '16px',
    fontFamily: fonts.din2014Regular,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: colors.primaryInverseText,
    '@media (max-width: 768px)': {
      fontSize: '4rem',
    },
  },
});
