import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  textCardContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    gap: '0.75rem',
    padding: '2.5rem',
    height: '41.1rem',
    width: '100%',
    fontFamily: fonts.din2014Light,
    backgroundColor: colors.brandGhostBackground,
    '@media (max-width: 768px)': {
      gridСolumn: '1 / -1',
      padding: '2rem',
      height: 'auto',
    },
  },
  textCardExperience: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  textCardYears: {
    fontFamily: fonts.din2014Light,
    fontSize: '8rem',
    fontWeight: 300,
    '@media (max-width: 768px)': {
      fontSize: '21rem',
    },
  },
  textCardLine: {
    border: `0.01rem solid ${colors.tertiaryBorder}`,
  },
  textCardText: {
    fontFamily: fonts.din2014Light,
    fontSize: '1.25rem',
    fontWeight: 300,
    lineHeight: '140%',
    '@media (max-width: 768px)': {
      fontSize: '4.3rem',
    },
  },
});
