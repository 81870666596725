import mp from '@/shared/ui/videos/sminex.mp4';

export interface MockVideoType {
  autoPlay: boolean; // влияет на рендер кнопки плэй
  type: string;
  preview: string; // если есть грузи по урл или по id_resource
  link: string; // урл || id_resource
}

export const mockVideo: MockVideoType[] = [
  {
    autoPlay: false,
    type: 'local',
    // previewType:'video'
    preview: '',
    link: mp,
  },
  {
    autoPlay: false,
    type: 'server',
    preview: 'yI093iPGUhM',
    link: 'yI093iPGUhM',
  },
];
