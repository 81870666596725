import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
    employerTitle: {
        fontFamily: fonts.montserratExtraLight,
        fontSize: '3.3rem',
        fontWeight: '300',
        lineHeight: '133%',
        marginTop: '6.25rem',
        marginBottom: '3.125rem'
      }
})