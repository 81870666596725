import * as stylex from '@stylexjs/stylex';
import { palette } from './palette.stylex';
const DARK = '@media (prefers-color-scheme: dark)';
export const colors = stylex.defineVars({
  primaryText: { default: palette['grey-950'], [DARK]: palette['greyWhite'] },
  primaryInverseText: { default: palette['greyWhite'], [DARK]: palette['grey-950'] },
  secondaryText: { default: palette['darked-70'], [DARK]: palette['lighted-70'] },
  tertiaryText: { default: palette['darked-50'], [DARK]: palette['lighted-50'] },
  ghostText: { default: palette['darked-30'], [DARK]: palette['lighted-30'] },
  accentText: { default: palette['bronze-500'], [DARK]: palette['bronze-400'] },
  successText: { default: palette['green-500'], [DARK]: palette['green-500'] },
  warningText: { default: palette['yellow-500'], [DARK]: palette['yellow-500'] },
  errorText: { default: palette['red-500'], [DARK]: palette['red-500'] },
  infoText: { default: palette['blue-500'], [DARK]: palette['blue-500'] },
  primaryBackground: { default: palette['greyWhite'], [DARK]: palette['grey-950'] },
  deepBackground: { default: palette['grey-50'], [DARK]: palette['greyBlack'] },
  primaryInverseBackground: { default: palette['grey-950'], [DARK]: palette['greyWhite'] },
  secondaryBackground: { default: palette['grey-700'], [DARK]: palette['grey-300'] },
  tertiaryBackground: { default: palette['grey-300'], [DARK]: palette['grey-700'] },
  tertiaryForeground: { default: palette['grey-500'], [DARK]: palette['grey-500'] },
  ghostBackground: { default: palette['grey-50'], [DARK]: palette['grey-900'] },
  accentBackground: { default: palette['bronze-500'], [DARK]: palette['bronze-400'] },
  successBackground: { default: palette['green-50'], [DARK]: palette['green-50'] },
  warningBackground: { default: palette['yellow-50'], [DARK]: palette['yellow-50'] },
  errorBackground: { default: palette['red-50'], [DARK]: palette['red-50'] },
  infoBackground: { default: palette['blue-50'], [DARK]: palette['blue-50'] },
  hoverBackground: { default: palette['darked-5'], [DARK]: palette['lighted-5'] },
  activeBackground: { default: palette['darked-10'], [DARK]: palette['lighted-10'] },
  overlayBackground: { default: palette['darked-50'], [DARK]: palette['lighted-50'] },
  brandBackground: { default: palette['bronze-200'], [DARK]: palette['bronze-300'] },
  brandGhostBackground: { default: palette['bronze-50'], [DARK]: palette['bronze-100'] },
  primaryBorder: { default: palette['darked-40'], [DARK]: palette['lighted-40'] },
  inverseBorder: { default: palette['lighted-40'], [DARK]: palette['darked-40'] },
  secondaryBorder: { default: palette['darked-20'], [DARK]: palette['lighted-20'] },
  tertiaryBorder: { default: palette['darked-10'], [DARK]: palette['lighted-10'] },
  ghostBorder: { default: palette['darked-5'], [DARK]: palette['lighted-5'] },
  accentBorder: { default: palette['bronze-500'], [DARK]: palette['bronze-400'] },
  hoverBorder: { default: palette['darked-10'], [DARK]: palette['lighted-10'] },
  successBorder: { default: palette['green-500'], [DARK]: palette['green-500'] },
  warningBorder: { default: palette['yellow-500'], [DARK]: palette['yellow-500'] },
  errorBorder: { default: palette['red-500'], [DARK]: palette['red-500'] },
  infoBorder: { default: palette['blue-500'], [DARK]: palette['yellow-500'] },
  primaryIcon: { default: palette['grey-950'], [DARK]: palette['greyWhite'] },
  inverseIcon: { default: palette['greyWhite'], [DARK]: palette['grey-950'] },
  secondaryIcon: { default: palette['darked-70'], [DARK]: palette['lighted-70'] },
  tertiaryIcon: { default: palette['darked-50'], [DARK]: palette['lighted-50'] },
  ghostIcon: { default: palette['darked-30'], [DARK]: palette['lighted-30'] },
  accentIcon: { default: palette['bronze-500'], [DARK]: palette['bronze-400'] },
  secondaryInverseText: { default: palette['lighted-70'], [DARK]: palette['darked-70'] },
});
