import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { RowItem } from '../../datagrid/ui/mockData';

interface HousingSelectorBodyProps {
  rows: RowItem[];
  gridTemplateColumns: string;
}

export const HousingSelectorBody = ({ rows, gridTemplateColumns }: HousingSelectorBodyProps) => {
  return (
    <div {...stylex.props(styles.contentGrid)}>
      {rows.map((row) => (
        <div
          key={row.id}
          {...stylex.props(styles.tableRow)}
          role="button"
          onClick={() => console.log('redirect to object page')}
          style={{ gridTemplateColumns }}
        >
          <div {...stylex.props(styles.tableCell)}>{row.project}</div>
          {row.renderImg && <div {...stylex.props(styles.tableCell)}>{row.renderImg(row, styles.tableCellImg)}</div>}
          <div {...stylex.props(styles.tableCell)}>{row.bedrooms}</div>
          {row.renderSquare && (
            <div {...stylex.props(styles.tableCell)}>{row.renderSquare(row, styles.tableCellUnitMeasure)}</div>
          )}
          <div {...stylex.props(styles.tableCell)}>{row.size}</div>
          <div {...stylex.props(styles.tableCell)}>{row.type}</div>
          <div {...stylex.props(styles.tableCell)}>{row.floor}</div>
          <div {...stylex.props(styles.tableCell)}>{row.building ? row.building : '-'}</div>
          <div {...stylex.props(styles.tableCell)}>{row.number}</div>
          {row.renderPriceForSquare && (
            <div {...stylex.props(styles.tableCell)}>{row.renderPriceForSquare(row, styles.tableCellUnitMeasure)}</div>
          )}
          {row.renderFullCost && (
            <div {...stylex.props(styles.tableCell)}>{row.renderFullCost(row, styles.tableCellUnitMeasure)}</div>
          )}
          {row.renderTableActions && (
            <div {...stylex.props(styles.tableCell, styles.tableCellIcons)}>
              {row.renderTableActions(row, styles.icon)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
