import React from 'react';
import { ComponentConfig } from './employer-page-types';
import { CardWithFloatBtn } from '@/widgets/card-with-float-btn';
import { NavWrapper } from '@/features/navbar';
import { Video } from '@/features/video';
import { SectionList } from '@/features/section-list';
import { EmployerTitle } from '@/enteties/employer-title/EmployerTitle';
import { ProfileCard } from '@/enteties/profile-card';
import { VicePresidentsList } from '@/features/vice-presidents-list';
import { EmployerTask } from '@/enteties/employer-task/EmployerTask';
import { TransitionCard } from '@/widgets/transition-card';

export const componentMap = {
  section: (props: React.HTMLProps<HTMLElement>) => <section {...props} />,
  CardWithFloatBtn,
  NavWrapper,
  Video,
  SectionList,
  EmployerTitle,
  ProfileCard,
  VicePresidentsList,
  EmployerTask,
  TransitionCard,
};

export const renderComponent = <T,>({ component, props, children }: ComponentConfig<T>) => {
  const Component = componentMap[component] as React.ComponentType<T>;

  return (
    <Component {...props}>
      {children &&
        children.map((childConfig, index) => (
          <React.Fragment key={index}>{renderComponent(childConfig)}</React.Fragment>
        ))}
    </Component>
  );
};
