import { SortArrowsIcon } from '@/shared/ui/icons/SortArrowsIcon';
import stylex, { StyleXStyles } from '@stylexjs/stylex';
import React from 'react';

export interface ColumnItem {
  id: number;
  name: string;
  value: string;
  sortable?: boolean;
  hidden?: boolean;
  width?: string;
  renderCell?: (params: ColumnItem, style: StyleXStyles) => React.ReactNode;
}
export const columns: ColumnItem[] = [
  { id: 1, name: 'project', value: 'Проект', width: '15%' },
  { id: 2, name: 'schema', value: 'Схема', width: '7%' },
  { id: 3, name: 'bedrooms', value: 'Cпальни', width: '7%' },
  { id: 4, name: 'square', value: 'Площадь', width: '7%' },
  { id: 5, name: 'size', value: 'Размер', width: '6%' },
  { id: 6, name: 'type', value: 'Тип', width: '11%' },
  { id: 7, name: 'floor', value: 'Этаж', width: '5%' },
  { id: 8, name: 'building', value: 'Корпус', width: '6%' },
  { id: 9, name: 'number', value: 'Номер', width: '6%' },
  {
    id: 10,
    name: 'priceForm',
    value: 'Цена за М²',
    sortable: true,
    width: '10%',
    renderCell: (params, style) => (
      <>
        {params.value}
        <span {...stylex.props(style)}>
          <SortArrowsIcon />
        </span>
      </>
    ),
  },
  {
    id: 11,
    name: 'price',
    value: 'Стоимость',
    sortable: true,
    width: '11%',
    renderCell: (params, style) => (
      <>
        {params.value}
        <span {...stylex.props(style)}>
          <SortArrowsIcon />
        </span>
      </>
    ),
  },
  { id: 12, name: 'empty', value: '', width: '9%' },
];

//  На 11 колонок:'8% 7% 7% 6% 25% 5% 6% 6% 10% 11% 9%'
