import { menuContent } from '@/shared/mockData/menuMockData';
import { flattenMenu } from '../lib/flattenMenuItems';
import { DesktopMenu } from './desktop-menu/DesktopMenu';
import { MobileMenu } from './mobile-menu/MobileMenu';

export const flattenedMenuContent = flattenMenu(menuContent);

export const Menu = () => {
  return (
    <>
      <DesktopMenu menuContent={flattenedMenuContent} />
      <MobileMenu menuContent={menuContent} />
    </>
  );
};
