import { MouseEvent, useState } from 'react';
import { Next } from '../icons/Next/Next';
import styles from './float-btn.module.css';

export interface FloatBtnProps {
  redirectUrl: string;
  btnName: string;
}

export const FloatBtn = ({ redirectUrl, btnName }: FloatBtnProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: MouseEvent<HTMLAnchorElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();

    setPosition({
      x: e.clientX - rect.right + 40,
      y: e.clientY - rect.top - rect.height / 2,
    });
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <a
      href={redirectUrl}
      className={styles.vacanciesLink}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {btnName}
      <span className={styles.arrowWrapper}>
        <Next isHovered={isHovered} />
        {isHovered && (
          <div
            className={styles.movingCircle}
            style={{
              transform: `translate(${position.x}px, ${position.y}px) scale(${isHovered ? 1.5 : 1})`,
            }}
          />
        )}
      </span>
    </a>
  );
};
