import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import { typography } from '@/shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    width: '100%',
    padding:'6.6rem 0',
    borderBottom: '1px solid black',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      alignSelf: 'stretch',
    },
  },

  headerWithDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '24px',
    flex: '1 0 0',
    width: '50%',
    paddingRight: '128px',
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      gap: '16px',
      width: 'auto',
      paddingRight: '0px',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      paddingRight: '84px',
    },
  },
  header: {
    color: colors.primaryText,
    fontFamily: fonts.montserratExtraLight,
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '80px',
    '@media (max-width: 767px)': {
      fontSize: '30px',
      lineHeight: '40px',
      alignSelf: 'stretch',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: '39px',
      lineHeight: '47px',
    },
  },
  description: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: typography['fontSizeBody-2'],
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: typography['lineHeightBody-2'],
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
      fontSize: typography['fontSizeBody-4'],
      lineHeight: typography['lineHeightBody-4'],
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: typography['fontSizeCaption-2'],
      lineHeight: typography['lineHeightCaption-2'],
    },
  },
  blockList: {
    width: '50%',
    paddingRight: '80px',
    flex: '1 0 0',
    '@media (max-width: 767px)': {
      width: 'auto',
      paddingRight: '0px',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      paddingRight: '52px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    listStyle: 'none',
    '@media (max-width: 767px)': {
      alignSelf: 'stretch',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      gap: '6px',
    },
  },
  point: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: typography['fontSizeBody-2'],
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: typography['lineHeightBody-2'],
    '@media (max-width: 767px)': {
      fontSize: typography['fontSizeBody-4'],
      lineHeight: typography['lineHeightBody-4'],
      alignSelf: 'stretch',
    },
    '@media (min-width: 768px) and (max-width: 1200px)': {
      fontSize: typography['fontSizeCaption-2'],
      lineHeight: typography['lineHeightCaption-2'],
    },
  },
  number: {
    paddingRight: '10px',
    textAlign: 'right',
  },
  bottomLine: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.primaryText,
    padding: '0 11.7rem',
  },
});
