import { useState } from 'react';
import { SminexLogo } from '@/shared/ui/icons/Sminex';
import { menuSocMedia } from '@/shared/mockData/menuMockData';
import { flattenedMenuContent } from '@/features/header/ui/menu/ui';
import styles from './footer.module.css';

export const Footer = () => {
  const [colunmnItems] = useState(flattenedMenuContent);
  const filteredMenuItems = colunmnItems.filter(
    (item) => item.title !== 'Информация' && item.title !== 'СКОРО В ПРОДАЖЕ' && item.title !== 'Фрунзенская набережная'
  );
  const firstColumn = filteredMenuItems.slice(0, 4);
  const remainingItemsLength = filteredMenuItems.length - firstColumn.length;
  const secondColumn = filteredMenuItems.slice(firstColumn.length, firstColumn.length + remainingItemsLength);

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerSection}>
        <div className={styles.footerFirstColumn}>
          {firstColumn.map((item) => (
            <p key={item.id}>{item.title}</p>
          ))}
        </div>
        <div className={styles.footerLogoColumn}>
          <h2 className={styles.footerLogo}>
            <SminexLogo />
          </h2>
          <div className={styles.contactWrapper}>
            <p className={styles.mail}>contact-center@sminex.com</p>
            <p className={styles.phone}>+7 (495) 487-88-08</p>
            <p className={styles.callOrder}>Заказать звонок</p>
          </div>
          <div className={styles.footerSocial}>
            {menuSocMedia &&
              menuSocMedia.map((el) => (
                <div key={el.id} className={styles.footerSocialIcon}>
                  {el.icon}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.footerSecondColumn}>
          {secondColumn.map((item) => (
            <p key={item.id}>{item.title}</p>
          ))}
        </div>
      </div>

      <div className={styles.footerLegal}>
        <p>
          Данный Интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной
          офертой, определяемой положениями<br></br> Статьи 437 Гражданского кодекса Российской Федерации.
        </p>
        <p>
          © 2007-2024 ООО "Смайнэкс Управление Активами", адрес: 119017, Москва, Кадашёвская набережная, д. 6/1/2с1,
          <br></br>
          тел.: +7 495 324 72 11, факс: +7 (495) 125-02-57
        </p>
      </div>
    </footer>
  );
};
