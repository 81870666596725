import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { HousingSelectorHeader } from '../../header';
import { HousingSelectorBody } from '../../body/ui';
import { ColumnItem } from '../../header/ui/mockColumns';
import { RowItem } from './mockData';

interface HousingSelectorGridProps {
  columns: ColumnItem[];
  rows: RowItem[];
  minWidth?: string;
}

export const HousingSelectorGrid = ({ columns, rows, minWidth }: HousingSelectorGridProps) => {
  const gridTemplateColumns = columns
    .filter((column) => !column.hidden)
    .map((column) => column.width || 'auto')
    .join(' ');
  return (
    <div {...stylex.props(styles.scrollContainer)}>
      <div {...stylex.props(styles.tableContainer(minWidth))} role="datagrid">
        <HousingSelectorHeader columns={columns} gridTemplateColumns={gridTemplateColumns} />
        <HousingSelectorBody rows={rows} gridTemplateColumns={gridTemplateColumns} />
      </div>
    </div>
  );
};
