import { CardWithFloatBtnProps } from '../../model/card-with-float-btn-types';
import styles from './desktop-card-with-float-btn.module.css';
import { FloatBtn } from '@/shared/ui/float-btn/FloatBtn';

export const DesktopCardWithFloatBtn = ({btnName,title,firstSentence, secondSentence,redirectUrl}: CardWithFloatBtnProps) => {
  return (
    <div className={styles.headerContent}>
      <div className={styles.titleWrap}>
        <h1 className={styles.title}>{title}</h1>
        <FloatBtn redirectUrl={redirectUrl} btnName={btnName} />
      </div>
      <div className={styles.subTitle}>
        {firstSentence}
        <br />
        {secondSentence}
      </div>
    </div>
  );
};
