import { HOUSING_SELECTOR_CONSTS } from '@/shared/lib/consts';
import { AddToCompareIcon } from '@/shared/ui/icons/AddToCompareIcon';
import { AddToFavsIcon } from '@/shared/ui/icons/AddToFavsIcon';
import { PopoverZoomIn } from '@/shared/ui/popoover-zoom-in';
import { Popover } from '@/shared/ui/popover';
import tulupov from '@shared/ui/assets/tulupov.png';
import stylex, { StyleXStyles } from '@stylexjs/stylex';

export interface RowItem {
  id: number;
  project: string;
  schemaSrc: string;
  bedrooms: number;
  square: string;
  size: string;
  type: string;
  floor: string;
  building: string;
  number: string;
  priceForSquare: string;
  cost: string;
  measure: string;
  currency: string;
  fullCost: string;
  fullCurrency: string;
  isAddToCompare: boolean;
  isAddToFavs: boolean;
  renderImg?: (params: RowItem, style: StyleXStyles) => React.ReactNode;
  renderSquare?: (params: RowItem, style: StyleXStyles) => React.ReactNode;
  renderPriceForSquare?: (params: RowItem, style: StyleXStyles) => React.ReactNode;
  renderFullCost?: (params: RowItem, style: StyleXStyles) => React.ReactNode;
  renderTableActions?: (params: RowItem, style: StyleXStyles) => React.ReactNode;
}

export const rows: RowItem[] = [
  {
    id: 1,
    project: 'Малая Ордынка 19',
    schemaSrc: tulupov,
    bedrooms: 3,
    square: '172',
    size: 'M',
    type: 'Вилла',
    floor: '32',
    building: '2',
    number: '777',
    priceForSquare: '555 000',
    measure: 'м²',
    currency: '₽',
    cost: '23,45',
    fullCurrency: 'млн. ₽',
    fullCost: '23 450 000',
    isAddToCompare: true,
    isAddToFavs: false,
    renderImg: (params, style) => {
      return (
        <PopoverZoomIn
          children={<img {...stylex.props(style)} src={params.schemaSrc} alt={params.project}></img>}
          link="/"
        ></PopoverZoomIn>
      );
    },

    renderSquare: (params, style) => {
      return (
        <>
          {params.square}
          <span {...stylex.props(style)}> {params.measure}</span>
        </>
      );
    },

    renderPriceForSquare: (params, style) => {
      return (
        <>
          {params.priceForSquare}
          <span {...stylex.props(style)}> {params.currency}</span>
        </>
      );
    },
    renderFullCost: (params, style) => {
      return (
        <Popover
          children={
            <>
              {params.cost}
              <span {...stylex.props(style)}> {params.fullCurrency}</span>
            </>
          }
          content={`${params.fullCost} ${params.currency}`}
        ></Popover>
      );
    },
    renderTableActions: (params, style) => {
      return (
        <>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToCompareIcon checked={params.isAddToCompare} />
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_COMPARE}
          ></Popover>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToFavsIcon checked={params.isAddToFavs}></AddToFavsIcon>
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_FAVS}
            position="bottom-left"
          ></Popover>
        </>
      );
    },
  },
  {
    id: 2,
    project: 'Обыденский №1',
    schemaSrc: tulupov,
    bedrooms: 4,
    square: '27',
    size: 'S',
    type: 'Апартаменты',
    floor: '8',
    building: '1',
    number: '42',
    priceForSquare: '368 000',
    measure: 'м²',
    currency: '₽',
    cost: '32,56',
    fullCurrency: 'млн. ₽',
    fullCost: '32 560 000',
    isAddToCompare: true,
    isAddToFavs: false,
    renderImg: (params, style) => {
      return (
        <PopoverZoomIn
          children={<img {...stylex.props(style)} src={params.schemaSrc} alt={params.project}></img>}
          link="/"
        ></PopoverZoomIn>
      );
    },

    renderSquare: (params, style) => {
      return (
        <>
          {params.square}
          <span {...stylex.props(style)}> {params.measure}</span>
        </>
      );
    },

    renderPriceForSquare: (params, style) => {
      return (
        <>
          {params.priceForSquare}
          <span {...stylex.props(style)}> {params.currency}</span>
        </>
      );
    },
    renderFullCost: (params, style) => {
      return (
        <Popover
          children={
            <>
              {params.cost}
              <span {...stylex.props(style)}> {params.fullCurrency}</span>
            </>
          }
          content={`${params.fullCost} ${params.currency}`}
        ></Popover>
      );
    },
    renderTableActions: (params, style) => {
      return (
        <>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToCompareIcon checked={params.isAddToCompare} />
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_COMPARE}
          ></Popover>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToFavsIcon checked={params.isAddToFavs}></AddToFavsIcon>
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_FAVS}
            position="bottom-left"
          ></Popover>
        </>
      );
    },
  },
  {
    id: 3,
    project: 'Тишинский бульвар',
    schemaSrc: tulupov,
    bedrooms: 4,
    square: '289',
    size: 'XXL',
    type: 'Вилла',
    floor: '1',
    building: '',
    number: '52',
    priceForSquare: '905 000',
    measure: 'м²',
    currency: '₽',
    cost: '112,43',
    fullCurrency: 'млн. ₽',
    fullCost: '112 430 000',
    isAddToCompare: true,
    isAddToFavs: false,
    renderImg: (params, style) => {
      return (
        <PopoverZoomIn
          children={<img {...stylex.props(style)} src={params.schemaSrc} alt={params.project}></img>}
          link="/"
        ></PopoverZoomIn>
      );
    },

    renderSquare: (params, style) => {
      return (
        <>
          {params.square}
          <span {...stylex.props(style)}> {params.measure}</span>
        </>
      );
    },

    renderPriceForSquare: (params, style) => {
      return (
        <>
          {params.priceForSquare}
          <span {...stylex.props(style)}> {params.currency}</span>
        </>
      );
    },
    renderFullCost: (params, style) => {
      return (
        <Popover
          children={
            <>
              {params.cost}
              <span {...stylex.props(style)}> {params.fullCurrency}</span>
            </>
          }
          content={`${params.fullCost} ${params.currency}`}
        ></Popover>
      );
    },
    renderTableActions: (params, style) => {
      return (
        <>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToCompareIcon checked={params.isAddToCompare} />
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_COMPARE}
          ></Popover>
          <Popover
            children={
              <div {...stylex.props(style)}>
                <AddToFavsIcon checked={params.isAddToFavs}></AddToFavsIcon>
              </div>
            }
            content={HOUSING_SELECTOR_CONSTS.ADD_TO_FAVS}
            position="bottom-left"
          ></Popover>
        </>
      );
    },
  },
];
