import { ReactNode, useState } from 'react';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ZoomInIcon } from '../../icons/ZoomInIcon';
import { useNavigate } from 'react-router-dom';

interface PopoverZoomInProps {
  children: ReactNode;
  link: string;
}

export const PopoverZoomIn: React.FC<PopoverZoomInProps> = ({ children, link }) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  const navigate = useNavigate();

  return (
    <div {...stylex.props(styles.container)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {isPopoverVisible && (
        <div {...stylex.props(styles.popover)} onClick={() => navigate(`${link}`)}>
          <ZoomInIcon />
        </div>
      )}
    </div>
  );
};
