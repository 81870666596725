import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  tasksWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6.25rem',
    paddingBottom: '6rem',
    '@media (max-width: 768px)': {
      display: 'block',
      padding: '14rem 4rem',
    },
  },
  tasks: {
    width: '32rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontWeight: '300',
    fontSize: '1.1rem',
    '@media (max-width: 768px)': {
      fontSize: '4rem',
      width: '100%',
    },
  },
});
