import ReactDOM from 'react-dom';
import * as stylex from '@stylexjs/stylex';
import {styles} from './styles'
import { ReactNode } from 'react';


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal = ({ isOpen, onClose,children }:ModalProps) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div {...stylex.props(styles.modalOverlay)}>
      <div {...stylex.props(styles.modalContent)}>
        {children}
        <button {...stylex.props(styles.closeButton)} onClick={onClose}>
          &times;
        </button>
      </div>
    </div>,
    document.body
  );
};
