import React from 'react';
import { ISectionProps } from '../model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

const Section: React.FC<ISectionProps> = ({ sectionContent }) => {
  return (
    <>
      <div {...stylex.props(styles.container)}>
        <div {...stylex.props(styles.headerWithDescription)}>
          <div {...stylex.props(styles.header)}>{sectionContent.header}</div>
          <div {...stylex.props(styles.description)}>{sectionContent.description}</div>
        </div>

        <div {...stylex.props(styles.blockList)} id={sectionContent.id}>
          {sectionContent.list ? (
            <ol {...stylex.props(styles.list)}>
              {sectionContent.list.map((element, index) => (
                <li key={index} {...stylex.props(styles.point)}>
                  <span {...stylex.props(styles.number)}>{index + 1}.</span>
                  {element}
                </li>
              ))}
            </ol>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default Section;
