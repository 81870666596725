import { useState, useEffect } from 'react';
import { ProfileCardType } from '@/enteties/profile-card/model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface AllExperienceCardProps {
  data: ProfileCardType;
}

export const AllExperienceCard = ({ data }: AllExperienceCardProps) => {
  const [animatedYears, setAnimatedYears] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = data.years;
    /**
     Длительность анимации в миллисекундах
   */
    const duration = 2000;
    /**
     Интервал обновления состояния
   */
    const incrementTime = 30;

    const step = end! / (duration / incrementTime);

    const timer = setInterval(() => {
      start += step;
      setAnimatedYears(Math.min(Math.round(start), end!));

      if (start >= end!) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer);
  }, [data.years]);

  return (
    <div {...stylex.props(styles.textCardContainer)}>
      <div {...stylex.props(styles.textCardExperience)}>
        <h3 {...stylex.props(styles.textCardYears)}>{animatedYears}</h3>
        <p {...stylex.props(styles.textCardText)}>{data.expierence}</p>
      </div>
      <div {...stylex.props(styles.textCardLine)}></div>
      <p {...stylex.props(styles.textCardText)}>{data.description}</p>
    </div>
  );
};
